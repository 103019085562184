<template>
  <vx-card>
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4 class>Set Name</h4>
      <div class="flex flex-wrap justify-end items-center">
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
          <vx-tooltip text="Export Excel">
            <a
              :href="
                excelurl +
                  `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
              "
              target="_blank"
              v-if="this.archive != 'archive'"
            >
            </a>
          </vx-tooltip>

          <vx-tooltip text="Export Archive Excel">
            <a
              :href="
                excelurl +
                  `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
              "
              target="_blank"
              v-if="this.archive == 'archive'"
            >
              <vs-button class="px-4">
                <span class="d-inline-flex pr-5 text-white">Export</span>

                <img
                  src="@/assets/images/icons/export-excel.svg"
                  class="d-inline-flex"
                  height="20"
                  width="20"
                />
              </vs-button>
            </a>
          </vx-tooltip>
        </div>
      </div>
    </div>
    <AgGridVue
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="locations"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :paginationPageSize="paginationPageSize"
      :pagination="true"
      :suppressPaginationPanel="true"
    />
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import shootLocationService from "@/services/shootLocationService.js";
import SearchService from "@/services/searchService.js";
import ShootLocationStatus from "./ShootLocationStatus";
import EditShootLocation from "./EditShootLocation.vue";
import { BASEURL } from "@/config/index.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
  },
  props: ["listType"],
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getShootLocationList(val);
          return val;
        } else return 1;
      },
    },
  },

  data() {
    return {
      excelurl: BASEURL,
      organization_id: "",
      totalPages: 0,
      archive: this.listType,
      locations: [],
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      columnDefs: [],
    };
  },
  beforeMount() {
    eventBus.$on("refreshTabledepa", (e) => {
      this.getShootLocationList(this.currentPage);
    });
    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      {
        headerName: "Name",
        field: "location_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Status",
        field: "location_active",
        filter: false,
        width: 95,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: ShootLocationStatus,
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 80,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditShootLocation,
      },
    ];
  },

  methods: {
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "shootLocation", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.locations = data.data;
          } else {
            this.locations = [];
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getShootLocationList: function(currentPage) {
      let filter = {
        page: currentPage,
      };

      shootLocationService
        .getAllShootLocation(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.locations = data.data;
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    if (this.checkPermission(["department", "GET"])) {
      this.getShootLocationList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
