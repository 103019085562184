<template>
  <div>
    <div class="vx-row w-full">
      <router-link to="/master">
        <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
      </router-link>
    </div>

    <div class="vx-row w-full mb-base">
      <ShootLocationForm v-if="checkPermission(['shootLocation', 'POST'])" />
    </div>

    <div class="vx-row w-full">
      <vs-tabs>
        <vs-tab label="Set Name List" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <div>
              <ShootLocationList />
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import ShootLocationForm from "./ShootLocationForm.vue";
import ShootLocationList from "./ShootLocationList.vue";

export default {
  components: {
    ShootLocationForm,
    ShootLocationList,
  },

  data() {
    return {};
  },
};
</script>

<style></style>
