<template>
  <vx-card class="form-title" title="Add Set Name">
    <form>
      <div class="vx-row">
        <div class="vx-col md:w-3/4 mx-auto">
          <div class="vx-row mb-6">
            <div
              class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
            >
              <h6 class="mb-0">Set Name*</h6>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <div class>
                <vs-input
                  v-validate="'required'"
                  name="set_name"
                  v-model="form.location_name"
                  class="w-full"
                  rules="alpha"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('set_name')"
                >
                  {{ errors.first("set_name") }}
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Description</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-textarea v-model="description" class="mb-0" rows="4" />
                  </div>
                </div> -->
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
              <h6 class="mb-0">Status</h6>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <b-form-checkbox
                value="1"
                unchecked-value="2"
                v-model="form.location_active"
                name="check-button"
                switch
              />
            </div>
          </div>
        </div>
      </div>

      <!-- SUBMIT BUTTON -->
      <div class="vx-row">
        <div class="vx-col mx-auto">
          <vs-button
            :disabled="submitStatus"
            type="filled"
            @click.prevent="submitForm"
            class="mr-3 w-full sm:w-auto mb-3"
          >
            Submit
          </vs-button>

          <vs-button
            color="warning"
            type="border"
            class="w-full sm:w-auto mb-3"
            @click.prevent="clearForm"
          >
            Reset
          </vs-button>
        </div>
      </div>
    </form>
  </vx-card>
</template>

<script>
import CompanyService from "@/services/companyService.js";
import shootLocationService from "@/services/shootLocationService.js";
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  data() {
    return {
      excelurl: BASEURL,
      submitStatus: true,
      User: "Select User",
      description: "",
      form: {
        location_name: "",
        organization_id: localStorage.getItem("OrganizationID"),
        location_active: "1",
      },
      companies: [],
      value: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    organization_id() {
      return localStorage.getItem("OrganizationID");
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function(URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function(event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(
          that.excelurl +
            `/v2/department/import/excel?organization_id=${
              this.organization_id
            }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              // title: "Duplicate entries have not been imported.",
              text: data.error.message || "Error",
              iconPack: "feather",
              // time:8000,
              time: 35000,
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
          }
          that.$vs.loading.close();
          // that.$vs.notify({
          //   title: "Updated!",
          //   text: data.message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "success"
          // });
          eventBus.$emit("refreshTabledepa", data);
        })
        .catch((error) => {
          that.$vs.loading.close();
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          that.$vs.notify({
            title: "Error!",
            // title: "Duplicate entries have not been imported.",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          this.submitStatus = false;
          let payload = {
            location_name: this.form.location_name,
            organization_id: this.form.organization_id,
            // description: this.description,
            location_active: this.form.location_active,
          };
          shootLocationService
            .addShootLocation(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                eventBus.$emit("refreshTabledepa", data);

                this.clearForm();
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getCompanyList: function() {
      CompanyService.getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            // if (this.companies.length > 0) {
            //   this.form.company_id = this.companies[0].company_id;
            // }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.description = "";
      this.value = [];
      this.form.organization_id = localStorage.getItem("OrganizationID");
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.getCompanyList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
