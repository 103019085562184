<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div>
      <vs-popup
        :id="'popmodal' + params.data.location_id"
        title="Update Set Name"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row mb-6">
              <div class="col-md-4 text-left md:text-right align-self-center">
                <h5 class="mb-0">Set Name*</h5>
              </div>
              <div class="col-12 col-md-6">
                <div class>
                  <vs-input
                    v-validate="'required'"
                    name="department name"
                    v-model="form.location_name"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('department name')"
                    >{{ errors.first("department name") }}</span
                  >
                </div>
              </div>
            </div>

            <div class="flex justify-center">
              <vs-button
                :disabled="submitStatus"
                type="filled"
                @click.prevent="submitForm"
                class=""
              >
                Save
              </vs-button>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import shootLocationService from "@/services/shootLocationService.js";
export default Vue.extend({
  data() {
    return {
      submitStatus: true,
      form: {
        location_name: "",
        location_id: "",
        organization_id: localStorage.getItem("OrganizationID"),
        location_active: "1",
      },
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.location_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          let payload = {
            location_name: this.form.location_name,
            location_id: this.form.location_id,
            location_active: this.form.location_active,
          };
          shootLocationService
            .editShootLocation(payload, this.form.location_id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;

              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTabledepa", data);
                }, 1);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
            });
        }
      });
    },
  },
});
</script>
